// Contains the Javascript helpers for responsive image containers (`responsiveImageBox` CSS class)

( function ( _, $ ) {
    "use strict";

    var $window = $( window ),
        _useGetComputedStyle = !! window.getComputedStyle,          // Needed for IE8 support in getCSS()

        innerBracketSelector = ".inner-bracket",

        /**
         * @param {jQuery}   $responsiveImageBoxes
         * @param {Object}   [config]
         * @param {Object}   [config.autoResize=true]          updates the responsive image boxes automatically on window resize or orientation change
         *                                                     (if active, you must call `destroy()` to unbind)
         * @param {Object}   [config.useParentCache=false]
         * @param {Function} [config.beforeUpdate]             callback to run before updating the responsive image boxes
         * @param {Function} [config.afterUpdate]              callback to run after updating the responsive image boxes
         * @param {Function} [config.beforeReset]              callback to run before a reset
         * @param {Function} [config.afterReset]               callback to run after a reset
         * @param {Function} [config.beforeAdd]                callback to run before adding more responsive image boxes
         * @param {Function} [config.afterAdd]                 callback to run after adding more responsive image boxes
         * @param {Function} [config.beforeDestroy]            callback to run before destroying the instance
         * @param {Function} [config.afterDestroy]             callback to run after destroying the instance
         * @constructor
         */
        ResponsiveImageBoxes = function ( $responsiveImageBoxes, config ) {
            config || ( config = {} );

            this.autoResize = config.autoResize || config.autoResize === undefined;
            this.useParentCache = config.useParentCache;
            
            this.beforeUpdate = config.beforeUpdate;
            this.afterUpdate = config.afterUpdate;
            this.beforeReset = config.beforeReset;
            this.afterReset = config.afterReset;
            this.beforeAdd = config.beforeAdd;
            this.afterAdd = config.afterAdd;
            this.beforeDestroy = config.beforeDestroy;
            this.afterDestroy = config.afterDestroy;

            this.$boxes = $responsiveImageBoxes || $();
        };

    $.extend( ResponsiveImageBoxes.prototype, {

        init: function () {
            // Update the boxes
            this._update();

            // Bind handler to window resize and orientationchange events
            if ( this.autoResize ) {

                this._onWindowResize = debounce( this.update, 10, this );

                $window.resize( this._onWindowResize );
                $window.on( "orientationchange", this._onWindowResize );
            }

            this._initDone = true;

            return this;
        },

        update: function () {
            if ( this.beforeUpdate ) this.beforeUpdate();

            this._apply();

            if ( this.afterUpdate ) this.afterUpdate();

            return this;
        },

        add: function ( $newBoxes ) {
            if ( this.beforeAdd ) this.beforeAdd();

            this.$boxes.add( $newBoxes );
            this._apply();

            if ( this.afterAdd ) this.afterAdd();

            return this;
        },

        reset: function ( $newBoxes ) {
            if ( this.beforeReset ) this.beforeReset();

            this._cleanup();

            this.$boxes = $newBoxes || $();
            this._apply();

            if ( this.afterReset ) this.afterReset();

            return this;
        },

        destroy: function () {
            if ( this.beforeDestroy ) this.beforeDestroy();

            if ( this._onWindowResize ) {
                $window.off( "resize", this._onWindowResize );
                $window.off( "orientationchange", this._onWindowResize );
                this._onWindowResize = undefined;
            }

            this.$boxes = undefined;

            if ( this.afterDestroy ) this.afterDestroy();

            return this;
        },

        _cleanup: function () {
            if ( this._initDone ) {
                removeMaxHeightConstraints( this.$boxes );
                // todo add clean-up for second helper here
            }

            return this;
        },

        _apply: function () {
            if ( this._initDone ) {
                this._update();
            } else {
                this.init();
            }
        },

        _update: function () {
            applyMaxHeightConstraints( this.$boxes, { useParentCache: this.useParentCache } );
            // todo add second helper here
            return this;
        }

    } );


    function isString ( value ) {
        // Done as in the Lodash compatibility build
        return typeof value === 'string' || value && typeof value === 'object' && Object.prototype.toString.call(value) === '[object String]' || false;
    }

    function debounce( callback, delay, context ) {
        var timer;

        return function () {
            var _this = context || this,
                args = arguments;

            // Clear a timer which has been created earlier in the sequence of calls.
            timer && clearTimeout( timer );

            // (Re-)start the timer, ie create a new one.
            timer = setTimeout( function () {
                callback.apply( _this, args );
            }, delay );
        };
    }

    function getViewportHeight () {
        // Use $.windowHeight() from jQuery.documentSize if available
        return $.windowHeight ? $.windowHeight( { viewport: "layout" } ) : $window.height();
    }

    /**
     * Returns the computed style for a property, or an array of properties, as a hash.
     *
     * Building a CSS properties hash this way can be significantly faster than the more convenient, conventional jQuery
     * approach, $( elem ).css( propertiesArray ).
     *
     * The method is taken, verbatim, from jQuery.isInView.
     *
     * ATTN
     * ====
     *
     * We are using an internal jQuery API here: $.css(). The current signature was introduced in jQuery 1.9.0. It may
     * break without warning with any change of the minor version.
     *
     * For that reason, the $.css API is monitored by the tests in jQuery.isInView (in api.jquery.css.spec.js), which
     * verify that it works as expected.
     *
     * @param {HTMLElement}     elem
     * @param {string|string[]} properties
     * @param {Object}          [opts]
     * @param {boolean}         [opts.toLowerCase=false]  ensures return values in lower case
     * @param {boolean}         [opts.toFloat=false]      converts return values to numbers, using parseFloat
     *
     * @returns {Object}        property names and their values
     */
    function getCss ( elem, properties, opts ) {
        var i, length, name,
            props = {},
            _window = ( elem.ownerDocument.defaultView || elem.ownerDocument.parentWindow ),
            computedStyles = _useGetComputedStyle ? _window.getComputedStyle( elem, null ) : elem.currentStyle;

        opts || ( opts = {} );

        if ( ! $.isArray( properties ) ) properties = [ properties ];
        length = properties.length;

        for ( i = 0; i < length; i++ ) {
            name = properties[i];
            props[name] = $.css( elem, name, false, computedStyles );
            if ( opts.toLowerCase && props[name] && props[name].toLowerCase ) props[name] = props[name].toLowerCase();
            if ( opts.toFloat ) props[name] = parseFloat( props[name] );
        }

        return props;
    }

    /**
     * Returns the parent of a given element, in a jQuery wrapper.
     *
     * @param   {jQuery} $elem
     * @param   {string} position  the position style
     *
     * @returns {jQuery}
     */
    function getParent ( $elem, position ) {
        return position === "absolute" ? $elem.offsetParent() : position === "fixed" ? $window : $elem.parent();
    }

    /**
     * Takes an element and detects the height of its immediate parent. Returns the computed value, in px, as a
     * number (without the px unit).
     *
     * Optionally, the queried parent heights are cached while the outer function (applyMaxHeightConstraints) is
     * running. Then, the height is fetched from the cache if possible, rather than looked up in the DOM.
     *
     * @param   {jQuery}  $box
     * @param   {string}  [position]  the CSS position of the element ("absolute", "relative" etc)
     * @param   {boolean} [cache]     an optional cache for parent elements and their heights
     *
     * @returns {number}
     */
    function getParentHeight ( $box, position, cache ) {
        var height,
            $parent = getParent( $box, position ),
            index = cache && $.inArray( $parent, cache.$parents );

        if ( cache && index !== -1 ) {

            height = cache.parentHeights[index];

        } else {
            height = $parent === $window ? getViewportHeight() : $parent.height();

            if ( cache ) {
                cache.$parents.push( $parent );
                cache.parentHeights.push( height );
            }
        }

        return height;
    }

    /**
     * Makes sure that previous constraints set by applyMaxHeightConstraints() are removed.
     *
     * This allows the images and image containers to expand to their unconstrained size.
     *
     * @param {jQuery} $responsiveImageBoxes
     */
    function removeMaxHeightConstraints( $responsiveImageBoxes ) {

        $responsiveImageBoxes.children( innerBracketSelector ).each( function ( index, innerBracket ) {
            innerBracket.style.maxWidth = "";
        } );

    }

    /**
     * Applies max-height to responsive image boxes. That can only be done with a JS helper. The helper must be run
     * when the boxes are rendered, and whenever the layout changes in relevant ways - usually on window resize.
     *
     * Please note:
     *
     * - Unlike the CSS max-height, the JS-driven solution applies max-height all the time, without exceptions.
     *
     *   By contrast, the CSS rule gets ignored when max-height is set in percent, and the parent doesn't have an
     *   explicit height (ie ignored with height: auto). (There also is a CSS exception for parent position: absolute.)
     *
     * - When handling responsive image boxes with `position: fixed`, it is best to have jQuery.documentSize loaded.
     *
     *   For max-height values in percent, the viewport height serves as the reference. It is established with the
     *   $.windowHeight() function of jQuery.documentSize, if available. Without it, $( window ).height() is used
     *   instead.
     *
     *   By and large, the jQuery method is OK for the purpose, but positioning in iOS might be off when Safari switches
     *   to minimal UI (see the jQuery.documentSize docs, https://github.com/hashchange/jquery.documentsize#window-size-1).
     *
     * - Optionally, when max-height is set in percent, the parent height(s) can be cached for the duration of the
     *   calculation. Then, the height is fetched from the cache if possible, rather than looked up in the DOM.
     *
     *   The cache is not a win in every scenario. If each responsiveImageBox is wrapped in another element,
     *   individually, then the DOM lookup can't be avoided, and the preceding cache search adds even more time to the
     *   process (and the cache consumes memory, too). However, if many responsive image boxes share the same parent
     *   element, the cache speeds things up considerably.
     *
     * @param {jQuery}  $responsiveImageBoxes
     * @param {Object}  [options]
     * @param {boolean} [options.useParentCache=false]
     */
    function applyMaxHeightConstraints ( $responsiveImageBoxes, options ) {
        var innerBrackets = [],
            maxWidths = [],
            cache = options && options.useParentCache ? { $parents: [], parentHeights: [] } : undefined;

        // First off, make sure that previous constraints set by applyMaxHeightConstraints() are removed. This allows
        // the images and image containers to expand to their unconstrained size.
        //
        // This is important for parents which have their height set to "auto". They must be allowed to expand for a
        // correct measurement of their height, and they wouldn't expand with the JS-driven max-height constraint in
        // place.
        removeMaxHeightConstraints( $responsiveImageBoxes );

        // We only read values and do the calculations here, but do not apply the max-width setting to the DOM element.
        //
        // Applying the values right away would cause a reflow on every iteration. Rather, we apply the max-width in a
        // second round, so browsers have the opportunity to be smart and delay the reflow until after all values are
        // set.
        $responsiveImageBoxes.each( function ( index, box ) {

            var maxWidth, intrinsicRelativeHeight, $innerBracket,

                $box = $( box ),

                computed =  getCss( $box[0], ["maxHeight", "position", "paddingTop", "paddingBottom", "borderTopWidth", "borderBottomWidth"], { toLowerCase: true } ),
                maxHeight = parseFloat( computed.maxHeight ),

                isPercent = computed.maxHeight.slice( -1 ) === "%",
                position = ( isString( computed.position ) && computed.position !== "" ) ? computed.position : "static";

            // Bail out if max height computes as "none"
            if ( isNaN( maxHeight ) ) return;

            // Convert a percentage to an absolute value
            if ( isPercent ) {
                // NB max-height is always relative to the **content area** height of the parent, no matter how the
                // box-sizing of the parent is defined.
                //
                // (That's what getParentHeight returns. It caches results in order to speed up repeated lookups.)
                maxHeight = getParentHeight( $box, position, cache ) * maxHeight / 100;
            }

            // We have calculated the desired maxHeight of the responsiveImageBox. But to enforce it, we have to limit
            // the height of the innerBracket. So we need to subtract the vertical borders and padding of the
            // responsiveImageBox.
            maxHeight -= ( parseFloat( computed.paddingTop ) + parseFloat( computed.paddingBottom ) + parseFloat( computed.borderTopWidth ) + parseFloat( computed.borderBottomWidth ) );

            $innerBracket = $box.children( innerBracketSelector );
            intrinsicRelativeHeight = parseFloat( $innerBracket.data( "intrinsicRelativeHeight" ) );

            // Fail silently if the relative height data is missing or doesn't make sense. We are doing progressive
            // enhancement here, so there is no reason to throw an error and break things if it fails.
            if ( isNaN( intrinsicRelativeHeight ) || intrinsicRelativeHeight <= 0 || maxHeight < 0 ) return;

            // We'll act indirectly and set a maxWidth on the innerBracket in order to limit its height.
            maxWidth = maxHeight / intrinsicRelativeHeight;

            // Store the DOM element and max-width value
            innerBrackets.push( $innerBracket );
            maxWidths.push( maxWidth );

        } );

        // Apply the max-width values to the DOM
        $.each( innerBrackets, function ( index ) {
            var $innerBracket = innerBrackets[index],
                maxWidth = maxWidths[index];

            $innerBracket.css( "maxWidth", maxWidth + "px" );
        } );

    }

    /**
     * Export a global object
     */
    window.ResponsiveImageBoxes = ResponsiveImageBoxes;

} )( _, jQuery );